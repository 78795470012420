<template>
    <div id="wrapper">
        <navbar id="nav" class="animate__animated animate__fadeInLeft"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn />
                <div class="container-fluid">
                    <div class="row no-print" >
                        <div class="col-1" v-if="user.role == 'A'">
                            <button class="btn btn-success btn-block" data-toggle="modal" data-target="#mdlLoan" @click="clear_loan(); employee_status_filter = '1';"> Add  </button>
                        </div>
                        <div class="col-2" v-if="user.role == 'A' || user.role == 'K'">
                            <select class="form-control" v-model="archived_status">
                                <option value="unarchived"> Unarchived </option>
                                <option value="archived"> Archived </option>
                            </select>
                        </div>
                        <div class="col-2" v-if="user.role == 'A' || user.role == 'K'">
                            <select class="form-control" v-model="type_filter">
                                <option value="all"> All </option>
                                <option value="loan"> Loan </option>
                                <option value="accomodation"> Accomodation </option>
                                <option value="expense"> Expense </option>
                                <option value="fine"> Fine </option>
                                <option value="food"> Food </option>
                                <option value="transport"> Transport </option>
                            </select>
                        </div>
                        <span class="col-1" v-if="user.role == 'A'">
                            <v-checkbox class="mt-1 custom-checkbox-label" v-model="showSpecialStaffs" true-value="true" false-value="false" label="Specials"></v-checkbox>
                        </span>
                        <span class="ms-6" style="margin-top: 10px;" v-if="user.role == 'A'">
                            Month:
                        </span>
                        <div class="col-1" v-if="user.role == 'A' || user.role == 'K'">
                            <select class="form-control" v-model="month">
                                <option value=""></option>
                                <option v-for="m in 12" :key="m" v-bind:value="m"> {{ m }} </option>
                            </select>
                        </div>
                        <span class="ms-6" style="margin-top: 10px;" v-if="user.role == 'A'">
                            Year:
                        </span>
                        <div class="col-2" v-if="user.role == 'A' || user.role == 'K'">
                            <input type="number" min="2020" class="form-control" v-model="year">
                        </div>
                        <div class="col-1" v-if="user.role == 'A'">
                            <button class="btn btn-primary btn-block" @click="print_all()"> Print </button>
                        </div>
                    </div>

                    <v-data-table class="mt-3 no-print" :headers="headers" :items="rows" :footer-props="{'items-per-page-options': [15, 20, 25, -1]}" v-if="user.role == 'A' || user.role == 'K'">
                        <template v-slot:[`item.amount`]="{item}">
                            <span :class="{'text-danger': item.amount < 0}">
                                {{ (item.amount).toLocaleString() }}
                                {{ item.salary_type == 'Monthly' ? '$' : 'IQD.' }}
                            </span>
                        </template>

                        <template v-slot:[`item.datetime_create`]="{item}">
                            {{ item.datetime_create && isValidDate(item.datetime_create) ? new Date(item.datetime_create).toISOString().split('T')[0] : '' }}
                            {{ item.datetime_create && isValidDate(item.datetime_create) ? new Date(item.datetime_create).toLocaleTimeString([], {timeZone: 'America/Danmarkshavn', hour: '2-digit', minute: '2-digit'}) : '' }}
                        </template>
                        <template v-slot:[`item.la_date`]="{item}">
                            {{ item.la_date && isValidDate(item.la_date) ? new Date(item.la_date).toISOString().split('T')[0] : '' }}
                        </template>

                        <template v-slot:[`item.la_id`]="{item}">
                            <v-btn icon color="success" @click="employee_status_filter = null; getSingleLoanAccomodation(item);" data-toggle="modal" data-target="#mdlView"> <v-icon size="23"> mdi-eye </v-icon> </v-btn>
                            <v-btn v-if="user.role == 'A'" icon color="primary" @click="employee_status_filter = null; getSingleLoanAccomodation(item);" data-toggle="modal" data-target="#mdlLoan"> <v-icon size="23"> mdi-pencil </v-icon> </v-btn>
                            <v-btn v-if="user.role == 'A'" icon color="error" @click="delete_list(item.la_id)"> <v-icon> mdi-delete </v-icon> </v-btn>
                        </template>

                    </v-data-table>

                    <table class="table table-bordered table-striped text-center text-dark" v-else>
                        <thead>
                            <tr>
                                <th> Employee </th>
                                <th> Staff </th>
                                <th> Type </th>
                                <th> Amount </th>
                                <th> User </th>
                                <th> Date - Time </th>
                                <th> Actions </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in rows" :key="index">
                                <td> {{ item.employee_full_name }} </td>
                                <td> {{ item.staff_name }} </td>
                                <td> {{ item.la_type }} </td>
                                <td :class="{'text-danger': item.amount < 0}"> {{ (item.amount).toLocaleString() }} {{ item.salary_type == 'Monthly' ? '$' : 'IQD.' }} </td>
                                <td> {{ item.user }} </td>
                                <td> {{ item.datetime_create && isValidDate(item.datetime_create) ? new Date(item.datetime_create).toISOString().split('T')[0] : '' }} {{ item.datetime_create && isValidDate(item.datetime_create) ? new Date(item.datetime_create).toLocaleTimeString([], {timeZone: 'America/Danmarkshavn', hour: '2-digit', minute: '2-digit'}) : '' }} </td>
                                <td>
                                    <v-btn icon color="success" @click="getSingleLoanAccomodation(item)" data-toggle="modal" data-target="#mdlView"> <v-icon size="23"> mdi-eye </v-icon> </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <hr class="no-print">


                <!-- Print Table -->
                <table class="table table-bordered  text-center text-dark table-only-print" v-if="user.role == 'A'">
                    <thead>
                        <tr>
                            <th style="color: black;"> Type </th>
                            <th style="color: black;"> Amount </th>
                            <th style="color: black;"> Month </th>
                            <th style="color: black;"> Year </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(staff, index) in Array.from(new Set(all_rows.filter(obj => obj.special_staff == showSpecialStaffs).map(obj => obj.st_id)))">
                            <tr style="" :key="'ss' + index">
                                <td colspan="4" class="text-start" style="background-color: #EBEBF0 !important; color: blue;"> {{ staffs.find(obj => obj.st_id == staff).staff_name}} </td>
                            </tr>
                            <template v-for="(employee, index_emp) in Array.from(removeDuplicateObjects(all_rows.filter(obj => obj.st_id == staff).map(obj => { 
                                    return {
                                        emp_id: obj.emp_id,
                                        la_id: obj.la_id,
                                    }
                                 })))">
                                <tr style="" :key="'se' + employee.emp_id + employee.la_id + index_emp">
                                    <td colspan="4" class="text-start" style="background-color: #EBEBF0 !important; color: black; font-weight: 500;"> {{ all_rows.find(obj => obj.emp_id == employee.emp_id).employee_full_name }} </td>
                                </tr>
                                <tr style="" :key="'sn' + employee.emp_id + employee.la_id + index_emp + 1">
                                    <td colspan="4" class="text-start" style="background-color: #EBEBF0 !important; color: black; font-weight: 500; white-space: pre; font-size: 11pt;">{{ all_rows.find(obj => obj.emp_id == employee.emp_id && obj.la_id == employee.la_id).note }}</td>
                                </tr>
                                <tr v-for="(data, inx) in all_rows.filter(obj => obj.emp_id == employee.emp_id && obj.la_id == employee.la_id)" :key="'data' + data.la_id + inx">
                                    <td> {{ data.la_type }} </td>
                                    <td :class="{'text-danger': data.amount < 0}"> {{ (data.amount).toLocaleString() }} {{ data.salary_type == 'Monthly' ? '$' : 'IQD.' }} </td>
                                    <td> {{ data.month }} </td>
                                    <td> {{ data.year }} </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdlLoan">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" data-dismiss="modal" @click="clear_loan()">&times;</button>
                        <h5 class="mt-2"> Loan / Accomodation / Expense </h5>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <label class="col-sm-2 mt-2"> Staffs: </label>
                            <select v-model="loan_accomodation.staff_id" class="form-control col-sm-8 mb-2" id="staff-select">
                                <option value=""></option>
                                <option v-for="staff in staffs.filter(obj => obj.show_staff == '1' && obj.special_staff == showSpecialStaffs)" :key="staff.st_id" v-bind:value="staff.st_id"> {{ staff.staff_name }} </option>
                            </select>
                        </div>
                        <div class="row">
                            <label class="col-sm-2 mt-2"> Employee: </label>
                            <select v-model="loan_accomodation.emp_id" class="form-control col-sm-8 mb-2" id="employee-select">
                                <option value=""></option>
                                <option v-for="employee in employees.filter(obj => employee_status_filter ? obj.active_status == employee_status_filter : true)" :key="employee.emp_id" v-bind:value="employee.emp_id"> {{ employee.full_name }} </option>
                            </select>
                            <label class="col-sm-2 mt-1"> {{ region | country_filter }} </label>
                        </div>
                        <div class="row">
                            <label class="col-sm-2 mt-2"> Type: </label>
                            <select v-model="loan_accomodation.la_type" class="form-control col-sm-8 mb-2">
                                <option value="loan"> Loan </option>
                                <option value="accomodation"> Accomodation </option>
                                <option value="expense"> Expense </option>
                                <option value="fine"> Fine </option>
                                <option value="food"> Food </option>
                                <option value="transport"> Transport </option>
                            </select>
                        </div>
                        <div class="row">
                            <label class="col-sm-2 mt-2"> Salary type: </label>
                            <label class="col-sm-8 mt-2"> {{ loan_accomodation.salary_type }} </label>
                        </div>
                        <div class="row">
                            <label class="col-sm-2 mt-2"> Amount: </label>
                            <input v-model="loan_accomodation.amount" type="number" class="form-control col-sm-8 mb-2">
                            <label class="col-sm-1 mt-2"> {{ loan_accomodation.emp_id ? (employees.find(obj => obj.emp_id == loan_accomodation.emp_id).salary_type == 'Monthly' ? '$' : 'IQD.') : '' }} </label>
                        </div>
                        <div class="row">
                            <label class="col-sm-12 mt-2"> Remain:  <span class="ml-14"> ( {{ (Number(loan_accomodation.amount) - Number(list.reduce((acc, n) => acc + Number(n.amount), 0))).toLocaleString() }} ) </span> </label>
                            
                        </div>
                        <div class="row">
                            <label class="col-sm-2 mt-2"> Date: </label>
                            <input type="date" v-model="loan_accomodation.la_date" class="form-control col-sm-8 mb-2">
                        </div>
                        <div class="row">
                            <label class="col-sm-2 mt-2"> Archived: </label>
                            <select v-model="loan_accomodation.archived" class="form-control col-sm-8 mb-2">
                                <option value="unarchived"> Unarchived </option>
                                <option value="archived"> Archived </option>
                            </select>
                        </div>
                        <div class="row">
                            <label class="col-sm-2 mt-2"> Note: </label>
                            <textarea rows="3" v-model="loan_accomodation.note" class="form-control col-sm-10 mb-2"> </textarea>
                        </div>
                        <div class="row">
                            <button class="btn btn-success col-sm-2 mt-2" @click="push_item_list()"> <v-icon color="white"> mdi-plus </v-icon> </button>
                        </div>
                        <hr>
                        <div class="row">
                            <label class="col-sm-3 mb-2 text-center"> Amount </label>
                            <label class="col-sm-3 mb-2 text-center"> Month </label>
                            <label class="col-sm-3 mb-2 text-center"> Year </label>
                            <label class="col-sm-3 mb-2 text-center"> Actions </label>
                        </div>
                        <div class="row" v-for="(item, index) in list" :key="'l' + index">
                            <input v-model="item.amount" class="form-control col-sm-3 mb-2" @change="set_amount(item.lad_id || null, item.amount)">
                            <select v-model="item.month" class="form-control col-sm-3 mb-2" @change="set_month(item.lad_id || null, item.month)">
                                <option value=""></option>
                                <option value="1"> 1 </option>
                                <option value="2"> 2 </option>
                                <option value="3"> 3 </option>
                                <option value="4"> 4 </option>
                                <option value="5"> 5 </option>
                                <option value="6"> 6 </option>
                                <option value="7"> 7 </option>
                                <option value="8"> 8 </option>
                                <option value="9"> 9 </option>
                                <option value="10"> 10 </option>
                                <option value="11"> 11 </option>
                                <option value="12"> 12 </option>
                            </select>
                            <input v-model="item.year" type="number" class="form-control col-sm-3 mb-2" @change="set_year(item.lad_id || null, item.year)">
                            <v-btn color="error" class="col-sm-3 mb-2 text-center" @click="delete_item_list(index)"> <v-icon> mdi-delete </v-icon> </v-btn>
                        </div>
                        <div class="row">
                            <button class="btn btn-success btn-block mx-2 mt-8" @click="addList()" v-if="!loan_accomodation.la_id"> Add List <v-icon color="white"> mdi-plus </v-icon> </button>
                            <button class="btn btn-warning btn-block mx-2 mt-8" @click="updateList()" v-else> Update <v-icon color="white"> mdi-content-save </v-icon> </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdlView">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" data-dismiss="modal" @click="clear_loan()">&times;</button>
                        <h5 class="mt-2"> Loan / Accomodation </h5>
                    </div>
                    <div class="modal-body">
                        Employee: {{ employee_full_name }}
                        <br><br>
                        Country: {{ region | country_filter }}
                        <br><br>
                        Staff: {{ loan_accomodation.staff_id ? staffs.find(obj => obj.st_id == loan_accomodation.staff_id).staff_name : '' }}
                        <br><br>
                        Type: {{ loan_accomodation.la_type }}
                        <br><br>
                        Total: <span :class="{'text-danger': loan_accomodation.amount < 0}"> {{ (loan_accomodation.amount > 0 ? loan_accomodation.amount : (loan_accomodation.amount * (-1))).toLocaleString() }} {{ loan_accomodation.salary_type == 'Monthly' ? '$' : 'IQD.' }} </span>
                        <br><br>
                        Note:
                        <br>
                        <hr>
                        <div style="white-space: pre !important;">{{ loan_accomodation.note }}</div>
                        <table class="table table-bordered table-striped text-center text-dark mt-3">
                            <thead>
                                <tr>
                                    <th> Amount </th>
                                    <th> Month </th>
                                    <th> Year </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in list" :key="item.lad_id">
                                    <td>
                                        <span :class="{'text-danger': item.amount < 0}">
                                            {{ (item.amount).toLocaleString() }}
                                            {{ loan_accomodation.salary_type == 'Monthly' ? '$' : 'IQD.' }}
                                        </span>
                                    </td>
                                    <td> {{ item.month }} </td>
                                    <td> {{ item.year }} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import auth from '../auth';
export default {
    name: 'LoanAccomodation',
    data() {
        return {
            employee_status_filter: null,
            region: '',
            staffs: [],
            employees: [],
            employee_full_name: '',
            archived_status: 'unarchived',
            loan_accomodation: {
                la_id: null,
                staff_id: null,
                emp_id: null,
                la_type: 'loan',
                salary_type: null,
                amount: 0,
                note: null,
                la_date: new Date().toISOString().split('T')[0],
                archived: 'unarchived'
            },
            type_filter: 'all',
            month: '',
            year: null,
            list: [],
            rows: [],
            all_rows: [],
            user: {
                username: "",
                role: "",
                en_id: null
            },
            headers: [
                {
                    text: 'Employee',
                    align: 'start',
                    sortable: false,
                    value: 'employee_full_name'
                },
                {
                    text: 'Staff',
                    align: 'start',
                    sortable: false,
                    value: 'staff_name'
                },
                {
                    text: 'Type',
                    align: 'start',
                    sortable: false,
                    value: 'la_type'
                },
                {
                    text: 'Amount',
                    align: 'start',
                    sortable: true,
                    value: 'amount'
                },
                {
                    text: 'User',
                    align: 'start',
                    sortable: false,
                    value: 'user'
                },
                {
                    text: 'Date (Create)',
                    align: 'start',
                    sortable: false,
                    value: 'la_date'
                },
                {
                    text: 'Date - Time',
                    align: 'start',
                    sortable: true,
                    value: 'datetime_create'
                },
                {
                    text: 'Actions',
                    align: 'start',
                    sortable: false,
                    value: 'la_id'
                },
            ],
            showSpecialStaffs: "false"
        }
    },
    beforeCreate(){
        auth("LoanAccomodation");
    },
    created() {
        this.$http.post("/getLoggedInfo").then(({data}) => {
            this.user.username = data.username;
            this.user.role = data.type;
            this.user.en_id = data.en_id || null;

            this.$http.post("/staff/getData").then(({data}) => {
                this.staffs = data.sort((a, b) => a.staff_sort_code - b.staff_sort_code)
            });

            this.getData()

        });
        
    },
    methods: {
        getData() {
            if(this.user.role == 'E') {
                this.$http.get('/loan_accomodation/getByEngineer/' + this.type_filter + '/' + this.user.en_id + '/' + this.archived_status).then(r => {
                    this.rows = r.data
                })
            } else {
                this.$http.post('/loan_accomodation/all', {
                    filter: this.type_filter,
                    archived: this.archived_status,
                    year: this.year,
                    month: this.month
                }).then(r => {
                    this.rows = r.data
                })
            }
        },
        clear_loan() {
            this.loan_accomodation = {
                la_id: null,
                staff_id: null,
                emp_id: null,
                la_type: 'loan',
                salary_type: null,
                amount: 0,
                note: null,
                la_date: new Date().toISOString().split('T')[0],
                archived: 'unarchived'
            }
            this.list = []
        },
        delete_item_list(index) {
            this.$confirm('Are you sure you want to delete this item ?', '', 'question').then(() => {
                if(this.loan_accomodation.la_id) {
                    const lad_id = this.list[index].lad_id
                    this.$http.delete('/loan_accomodation/delete_detail_by_id/' + lad_id).then(() => {
                        this.list.splice(index, 1);
                    }).catch(() => {
                        alert('Delete Failed')
                    })
                } else {
                    this.list.splice(index, 1);
                }
            })
            
        },
        addList() {
            var d = new Date();
            d.setHours(d.getHours() + 3)
            const list = this.list.filter(obj => obj.amount && obj.month && obj.year)
            if(list.length == 0) return alert('Please fill all fields')
            if(!this.loan_accomodation.emp_id || !this.loan_accomodation.staff_id || !this.loan_accomodation.amount ) return alert('Please all fields')
            this.$http.post('/loan_accomodation/create_list', {
                loan_accomodation: {
                    emp_id: this.loan_accomodation.emp_id,
                    st_id: this.loan_accomodation.staff_id,
                    la_type: this.loan_accomodation.la_type,
                    amount: this.loan_accomodation.amount,
                    salary_type: this.loan_accomodation.salary_type,
                    datetime_create: d,
                    user: this.user.username,
                    note: this.loan_accomodation.note,
                    la_date: this.loan_accomodation.la_date,
                    archived: this.loan_accomodation.archived
                },
                list
            }).then(() => {
                this.getData();
                this.clear_loan();
            })
        },
        updateList() {
            const list = this.list.filter(obj => obj.amount && obj.month && obj.year)
            if(list.length == 0) return alert('Please fill all fields')
            if(!this.loan_accomodation.emp_id || !this.loan_accomodation.staff_id || !this.loan_accomodation.amount ) return alert('Please all fields')
            var d = new Date();
            d.setHours(d.getHours() + 3)
            this.$http.patch('/loan_accomodation/update_list', {
                loan_accomodation: {
                    la_id: this.loan_accomodation.la_id,
                    emp_id: this.loan_accomodation.emp_id,
                    st_id: this.loan_accomodation.staff_id,
                    la_type: this.loan_accomodation.la_type,
                    amount: this.loan_accomodation.amount,
                    salary_type: this.loan_accomodation.salary_type,
                    datetime_create: d,
                    user: this.user.username,
                    note: this.loan_accomodation.note,
                    la_date: this.loan_accomodation.la_date,
                    archived: this.loan_accomodation.archived
                }
            }).then(() => {
                this.getData();
                this.clear_loan();
            })
        },
        getSingleLoanAccomodation(item) {
            this.loan_accomodation = {
                la_id: null,
                staff_id: null,
                emp_id: null,
                la_type: 'loan',
                salary_type: null,
                amount: 0,
                note: null,
                la_date: new Date().toISOString().split('T')[0],
                archived: 'unarchived'
            }
            this.list = []

            
            this.loan_accomodation.la_id = item.la_id;
            this.loan_accomodation.staff_id = item.st_id;
            setTimeout(() => {
                this.loan_accomodation.emp_id = item.emp_id;
                this.region = item.country
            }, 200)
            this.loan_accomodation.la_type = item.la_type;
            this.loan_accomodation.salary_type = item.salary_type;
            this.loan_accomodation.amount = item.amount;
            this.loan_accomodation.note = item.note;
            this.loan_accomodation.la_date = new Date(item.la_date).toISOString().split('T')[0];
            this.loan_accomodation.archived = item.archived;
            this.employee_full_name = item.employee_full_name;
            
            this.$http.get('/loan_accomodation/get_detail/' + item.la_id).then(r => {
                this.list = r.data
            })
        },
        delete_list(la_id) {
            this.$confirm('Are you sure you want to delete this list ?', '', 'question').then(() => {
                this.$http.delete('/loan_accomodation/delete_by_id/' + la_id).then(() => {
                    this.getData();
                }).catch(() => {
                    alert('Delete Failed')
                })
            })
        },
        set_amount(lad_id, amount) {
            if(this.loan_accomodation.la_id) {
                this.$http.patch('/loan_accomodation/set_amount/' + lad_id, {
                    amount
                }).then(() => { }).catch(() => {
                    alert('Update Failed')
                })
            }
        },
        set_month(lad_id, month) {
            if(this.loan_accomodation.la_id) {
                this.$http.patch('/loan_accomodation/set_month/' + lad_id, {
                    month
                }).then(() => { }).catch(() => {
                    alert('Update Failed')
                })
            }
        },
        set_year(lad_id, year) {
            if(this.loan_accomodation.la_id) {
                this.$http.patch('/loan_accomodation/set_year/' + lad_id, {
                    year
                }).then(() => { }).catch(() => {
                    alert('Update Failed')
                })
            }
        },
        push_item_list() {
            if(this.loan_accomodation.la_id) {
                this.$http.post('/loan_accomodation/add_detail', {
                    la_id: this.loan_accomodation.la_id,
                    amount: 0,
                    month: new Date().getMonth() + 1,
                    year: new Date().getFullYear()
                }).then((r) => {
                    this.list.push({lad_id: r.data.new_id, la_id: this.loan_accomodation.la_id, amount: 0, month: new Date().getMonth() + 1, year: new Date().getFullYear()})
                }).catch(() => {
                    alert('Add Failed')
                })
            } else {
                this.list.push({la_id: null, amount: 0, month: null, year: new Date().getFullYear()})
            }
        },
        isValidDate(d) {
            let date = new Date(d);
            return date instanceof Date && !isNaN(date);
        },
        print_all() {
            this.all_rows = []
            let ids = this.rows.map(obj => obj.la_id);
            this.$http.post('/loan_accomodation/getAllDetailByIds', {
                ids
            }).then(r => {
                this.all_rows = r.data;
                setTimeout(() => {
                    window.print();
                }, 500)
            })
        },
        removeDuplicateObjects(inputArray) {
            const uniqueObjects = [];
            const uniqueSet = new Set();

            for (const obj of inputArray) {
                const objString = JSON.stringify(obj);
                if (!uniqueSet.has(objString)) {
                uniqueSet.add(objString);
                uniqueObjects.push(obj);
                }
            }

            return uniqueObjects;
        }
    },
    watch: {
        'loan_accomodation.staff_id': function (value) {
            this.employees = [];
            this.loan_accomodation.emp_id = null
            if(value){
                this.$http.post("/employee/getEmployeeBystaffForLoan/" + this.loan_accomodation.staff_id).then(({data}) => {
                    this.employees = data;
                });
            } else {
                this.employees = [];
            }
        },
        'loan_accomodation.emp_id': function (value) {
            if(document.activeElement == document.getElementById('employee-select')) {
                if(value){
                    this.region = this.employees.find(obj => obj.emp_id == value).country;
                    this.loan_accomodation.salary_type = this.employees.find(obj => obj.emp_id == value).salary_type;
                } else {
                    this.region = ''
                    this.loan_accomodation.salary_type = null;
                }
            }
        },
        archived_status() {
            this.getData();
        },
        type_filter() {
            this.getData();
        },
        month() {
            this.getData();
        },
        year() {
            this.getData();
        },
    },
    filters: {
        country_filter(value) {
            return {
                "1": "Iraq",
                "2": "Foreign",
                "3": "Iran",
                "4": "Bangladish",
                "5": "Syria",
                "6": "India",
                "7": "Pakistan",
            }[value]
        }
    }
}


</script>

<style scoped>
    .rtl {
        text-align: right !important;
    }
    tbody td {
        padding: .5rem;
    }
    .para {
        background-color: rgb(81, 224, 81) !important;
        color: black !important;
    }
    .para-total {
        background-color: rgb(68, 171, 231) !important;
        color: black !important;
    }
    th, td {
        font-size: 12pt;
        color: black;
        font-weight: 500;
    }
    .table-only-print tbody tr td {
        padding-top: 3px;
        padding-bottom: 3px;
    }
    .table-only-print {
        display: none;
    }
    @media print {
        .no-print, #nav {
            display: none !important;
        }
        .table-only-print {
            display: table !important;
        }
        *:not(.para, .para-total, .expired-passport-date) {
            background-color: white ;
            
        }
        .para {
            background-color: rgb(81, 224, 81) !important;
            color: black !important;
        }
        .para-total {
            background-color: rgb(68, 171, 231) !important;
            color: black !important;
        }
        table {
            font-size: 9pt !important;
        }
        @page {
            size: landscape;
        }
    }
</style>